body {
  background-color: #f5f8fb;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.container {
  width: 100%;
  height: 200px;
  top: 10%;
  left: 10%;
  margin-top: 10px;
}

.sigContainer {
  width: 100%;
  height: 70%;
  margin: 0 auto;
  background-color: rgb(223, 223, 223);
  border: 1px solid;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 50%;
  height: 30px;
  background-color: green;
  color: white;
}

.sigImage {
  background-size: 100%;
  width: 200px;
  height: 100px;
  background-color: white;
  margin-top: 6rem;
  margin-left: 4rem;
  padding: 1rem;
}
